<template>
  <div>
    <v-container class="px-5">
      <v-card class="mx-auto" max-width="900" outlined color="transparent">
        <v-card-text>
          <div class="mt-10 text--primary text-h5">
            <span>Vision</span>
          </div>
          <v-divider></v-divider>
          <br />
          <div class="text--primary">
            <p>
              우리의 프로젝트가 한국 및 캐나다 내의 한인 교회와 기독교인들의
              성소수자 대한 그들의 편견을 없애고, 그들을 온전히 포용하는
              Affirming Ministry가 증가하기를 기대한다. 또한 앞으로 더 많은
              기독교인들이 성소수자 이슈에 대해서 진지하게 고민하고 연구하여,
              기존의 왜곡된 사실들에 대해서 인식하고, 그 동안의 편견, 폭력,
              차별에 대해서 반성하여, 성소수자들과 연대할 수 있는 발판이 되길
              기대한다.
            </p>
            <p>
              <b>Vision:</b> 예수의 사역을 이어받아 이 땅 위에 차별과 배제 대신
              평등과 환대가 넘치게 하기를 기원한다.
            </p>
            <p>
              <b>Mission:</b> 호모포비아적(이성애자 중심의 혐오적)이데올로기를
              바탕으로 한 성서 읽기, 해석, 가르침으로 인해 발생하는 낙인 및
              차별에 저항하는 한국 내(/외)의 운동에 힘을 실어준다.
            </p>
          </div>
        </v-card-text>
      </v-card>
      <v-img
        style="margin:auto;"
        src="../assets/images/introduce/united.jpg"
        max-width="500"
        max-height="500"
        contain
      />
      <p class="d-flex justify-center mt-3" style="position:relative;">
        DESIGNED BY YOONJOO SHIN
      </p>
    </v-container>
  </div>
</template>

<script>
import Slider from '../components/Slider';

export default {
  name: 'Home',
  components: { Slider },
};
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
  color: #9575cd;
}
.circle-image {
  border-radius: 100%;
}
ul {
  list-style: none;
}
ul li::before {
  content: '\2022';
  color: #4caf50;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
* {
  line-height: 2;
}
</style>
